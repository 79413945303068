import * as React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-grid-system';
import Layout from '../components/layout';
import Seo from '../components/seo';

import Ecg from '../icons/electrocardiography.svg';
import Echo from '../icons/echocardiography.svg';
import Exercise from '../icons/exercise-stress-testing.svg';
import MyViewLogo from '../images/my_view_logo.svg';

// const Layout = React.lazy(() => import('../components/layout'));
import CtaFluid from '../components/rows/cta/ctaFluid';
const Column = React.lazy(() => import('../components/elements/column'));
const HomeBanner = React.lazy(() => import('../components/rows/banner'));
const OneCol = React.lazy(() => import('../components/rows/onecol'));
const TwoCol = React.lazy(() => import('../components/rows/twocol'));
const Cta = React.lazy(() => import('../components/rows/cta'));
const Button = React.lazy(() => import('../components/elements/button'));
const Testimonials = React.lazy(() =>
  import('../components/elements/slider/testimonial')
);

const FeaturedDoctors = React.lazy(() =>
  import('../components/elements/doctors/featured')
);

export default function index({ data }) {
  const isSSR = typeof window === 'undefined';
  return (
    <>
      {!isSSR && (
        <Layout className="home">
          <React.Suspense fallback={<div style={{ minHeight: '100vh' }} />}>
            <Seo
              title="Home"
              description="Learn why PACE Cardiology is a world-renowned cardiac service; providing the very best in diagnostic results 
        and individualized care with the goal to improve heart health for everyone."
            />
            <HomeBanner data={data.allStrapiSlideshows.edges} />
            <OneCol
              blueBG
              title="Ocean eReferral Network "
              description="PACE Cardiology is pleased to be a part of the Ocean eReferral Network, which lets healthcare providers securely send electronic referrals in real-time while keeping patients informed. "
              link="Learn More Information"
              href="/patient-oceanreferral/"
            />
            {data.allStrapiHome.edges.map(({ node }) => (
              <React.Fragment key={node}>
                <TwoCol
                  title={node.home_twocol.title}
                  description={node.home_twocol.description}
                  link={node.home_twocol.button_title}
                  href={node.home_twocol.button_link}
                  pdf
                  newTab
                  image={node.home_twocol.image.localFile.publicURL}
                />
                <div className="bg-gray-100 py-52 mt-52">
                  <Container>
                    <div className="text-center mx-auto md:w-6/12 twocol-description">
                      <h2>
                        Our <strong className="text-red-300">heart</strong> care
                        services
                      </h2>
                      <p>
                        Along with cardiology consultations, we offer
                        specialized medical services, electrophysiology and
                        non-invasive cardiac testing options.
                      </p>
                    </div>
                    <div className="icon-row flex flex-wrap my-40 justify-between">
                      <Column
                        icon={<Ecg />}
                        link={`/services/${node.home_threecol.col1_link}`}
                        title={node.home_threecol.col1_title}
                        description={node.home_threecol.col1_description}
                      />
                      <Column
                        icon={<Echo />}
                        link={`/services/${node.home_threecol.col2_link}`}
                        title={node.home_threecol.col2_title}
                        description={node.home_threecol.col2_description}
                      />
                      <Column
                        icon={<Exercise />}
                        link={`/services/${node.home_threecol.col3_link}`}
                        title={node.home_threecol.col3_title}
                        description={node.home_threecol.col3_description}
                      />
                    </div>
                    <div className="flex justify-center">
                      <Button
                        ghost
                        link="See a full list of our services"
                        href="/services"
                      />
                    </div>
                  </Container>
                </div>
                <div className="mb-56">
                  <CtaFluid
                    // imgSrc, title, subTitle, btnName, btnLink, bgColor
                    subTitle="MyVIEW Cardiac Wellness Program,  a convenient and effective way to improve your heart health."
                    imgSrc={MyViewLogo}
                    title="MyVIEW Cardiac Wellness."
                    btnName="Learn More Today"
                    btnLink="https://my-view.ca/"
                    bgColor="#1D3557"
                    isDirectLink
                  />
                </div>

                <TwoCol
                  reverse
                  title={node.home_twocol2.title}
                  description={node.home_twocol2.description}
                  link={node.home_twocol2.button_title}
                  href={node.home_twocol2.button_link}
                  image={node.home_twocol2.image.localFile.publicURL}
                />
                <Cta />
              </React.Fragment>
            ))}
            <FeaturedDoctors
              data={data.allStrapiTeams.edges}
              subtitle="Our team of cardiologists, physician assistants, nurse practitioners, wellness coaches and genetic counsellors are available to help at our Barrie and Newmarket locations. We also have an echocardiography lab in Orillia."
              title='Our <span class="text-red-300">specialist</span> team'
              isShowDiscoverTeam
              customClass="max-w-7xl mx-auto my-0 pt-10 pb-20 text-center"
            />
            <Testimonials data={data.allStrapiTestimonials.edges} />
          </React.Suspense>
        </Layout>
      )}
    </>
  );
}

export const query = graphql`
  query Home {
    allStrapiHome {
      edges {
        node {
          id # id of the node
          home_twocol {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          home_twocol2 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          home_threecol {
            id
            col1_title
            col1_link
            col1_description
            col2_title
            col2_link
            col2_description
            col3_title
            col3_link
            col3_description
          }
        }
      }
    }
    allStrapiSlideshows {
      edges {
        node {
          id
          title
          button_tiitle
          link
          banner {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allStrapiTestimonials {
      edges {
        node {
          id
          testimonial
          author
        }
      }
    }
    # if fields: order_number == null > set fields id
    allStrapiTeams(filter: {featured: {eq: true}}, sort: {fields: order_number}) {
      edges {
        node {
          id
          name
          slug
          order_number
          picture {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
